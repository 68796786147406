import Cookies from "js-cookie"
import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from "react"
import { User } from "../types";

export const COOKIE_NAME = "game_jwt";

export interface Session {
  access_token: string,
  refresh_token: string,
  username: string,
  id: string,
  duration: number,
  username_changed: boolean,
  // Optional
  email?: string,
  wallet?: string,
}

export const SessionContext = React.createContext?.<{
  session?: Session,
  setSession: Dispatch<SetStateAction<Session | undefined>>,
  user?: User,
  setUser: Dispatch<SetStateAction<User | undefined>>
}>({setUser: () => {}, setSession: () => {}})


export function getSession(): Session | undefined {
  const cookie = Cookies.get(COOKIE_NAME);
  return cookie ? JSON.parse(cookie) : undefined;
}

export const SessionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [session, setSession] = useState<Session | undefined>(getSession());
  const [user, setUser] = useState<User | undefined>();

  return (
    <SessionContext.Provider value={{ session, setSession, user, setUser }}>
      {children}
    </SessionContext.Provider>
  );
};
