// export const HELIUS_DEVNET = "https://devnet.helius-rpc.com/?api-key=04a4beab-1def-4072-90f6-e381da22a609";
// FIXME: in prod use:
export const HELIUS_DEVNET = "https://rpc-proxy.elements-crystalsofpower.workers.dev";

export const IS_DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === "development";

const protocol = IS_DEV ? "http" : "https";
export const VERCEL_URL_FULL = `${protocol}://${process.env.NEXT_PUBLIC_VERCEL_URL}`;

export const battlepassLayoutConfig = [
  { title: "Starter Deck", subtitle: "Pick your element", image: "/images/battlepass/starter_deck.jpg" },
  { title: "3 Genesis Packs", subtitle: "Full of cards, $20~ value", image: "/images/battlepass/3_genesis_packs.png" },
  { title: "Crystal Battlepass", subtitle: "Adventures & Unique Cards", image: "/images/battlepass/basic.jpg" },
  { title: "Unique Cosmetics", subtitle: "Flex that you were early", image: "/images/battlepass/skins.jpg" },
];
