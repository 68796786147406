"use client";

import { Suspense, useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { getSession, Session } from "./SessionProvider";
import { getGameApiUrl } from "../_util/util";

export function ReferralHandler() {
  return <Suspense><ReferralHandlerRaw /></Suspense>
}

function ReferralHandlerRaw() {
  const searchParams = useSearchParams();

  useEffect(() => {
    const referral = searchParams.get("referral");
    if (referral) {
      localStorage.setItem("referral_code", referral);
      console.log(`Referral code detected: ${referral}`);
    }
  }, [searchParams]);

  return null; // This component has no visible output
}

export const sessionFromCookie = async (setSession: Function) => {
  const session = getSession();
  if (session) {
    // Referral, done in a separate query for simplicity (hard to forward the referral everywhere)
    await sendReferralCode(session);
    // Update the session provider
    setSession(session);
  }
};

export const sendReferralCode = async (session: Session) => {
  try {
    // Retrieve referral code from localStorage
    const referralCode = localStorage.getItem("referral_code");

    if (!referralCode) {
      console.warn("No referral code found in localStorage.");
      return;
    }

    // Send the referral code to the API
    const response = await fetch(getGameApiUrl(`/users/edit/${session.id}`), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: session.access_token,
      },
      body: JSON.stringify({
        referred_by: referralCode,
      }),
    });

    if (!response.ok) {
      console.error(`Failed to save referral code. Status: ${response.status}`);
      return;
    }

    localStorage.removeItem("referral_code"); // Cleanup
    console.log("Referral code successfully saved.");
    return await response.json();
  } catch (error) {
    console.error("Error while sending referral code:", error);
  }
};

