import { IS_DEV } from "../config";
import { CardInstanceFull } from "../types";

export function getBaseUrl() {
  return IS_DEV ? "http://localhost:3000" : "https://playtriad.com";
}

export function getGameApiBaseUrl() {
  return IS_DEV ? "http://localhost:8080/" : "https://api.playtriad.com";
}

export function getGameUrl() {
  return IS_DEV ? "http://localhost/" : "https://game.playtriad.com/";
}

export function getCookieDomain() {
  return IS_DEV ? ".localhost" : ".playtriad.com";
}

export function getImxExplorerBaseUrl() {
  return IS_DEV ? "https://explorer.testnet.immutable.com" : "https://explorer.immutable.com";
}

export function getImxCardContractId() {
  return IS_DEV ? "0xB4B9FeCe5f17fB46a4c58C9B316ea70BcD2cBf71" : "0xb4b9fece5f17fb46a4c58c9b316ea70bcd2cbf71";
}

export function getImxBattlepassContractId() {
  return IS_DEV ? "0xbad356c1ff60a03560ad7cd513a8476b71043ce9" : "0x19ec3325839a9326b02c70ad3e7dd7819e707cba";
}

export function getGameApiUrl(path: string) {
  return new URL(path, getGameApiBaseUrl()).toString();
}

export function isChromeBrowser(): boolean {
  const userAgent = window.navigator.userAgent;

  // Check for "CriOS" in the user agent, indicating Chrome on iOS
  const isIos = /iphone|ipod|ipad/i.test(navigator.userAgent);
  const isIOSChrome = /CriOS/i.test(userAgent);

  // General Chrome detection for other platforms
  // @ts-ignore
  const isChromium = /Chrome/i.test(userAgent) && !/Edg|OPR|SamsungBrowser/i.test(userAgent);
  const vendorName = window.navigator.vendor === "Google Inc.";

  return isIos ? isIOSChrome : (isChromium && vendorName);
}

export function getCardPictureName(card: CardInstanceFull, ext = "jpg") {
  const variant_suffix = card.variant == "normal" ? "" : "__" + card.variant;
  const altart_suffix = card.altart == "" ? "" : "__" + card.altart;
  return `${card.tid}${variant_suffix}${altart_suffix}.${ext}`;
}

export function getCardPictureUrl(card: CardInstanceFull) {
  return `${getBaseUrl()}/images/cardlib/jpg/${getCardPictureName(card)}`;
}